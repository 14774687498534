import { HttpClient } from '@angular/common/http';
import { IJobLog } from '../interfaces/joblog.interface';
import { AdminBaseService } from './admin-base.service';
import { AdminAgentCommandType } from '../domain';

export class AdminAgent extends AdminBaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  public async execute(cmd: AdminAgentCommandType, params: any = {}): Promise<any> {
    return this.postAsync(`/light/api/background-tasks/admin-agent/execute/${cmd}`, params);
  }

  public async getLastExecuted(): Promise<IJobLog> {
    return this.getAsync(`/light/api/background-tasks/admin-agent/getLastExecuted`);
  }

  public async getExecutions(): Promise<IJobLog[]> {
    return this.getAsync(`/light/api/background-tasks/admin-agent/executions`);
  }

  public async getExecution(id: string): Promise<IJobLog[]> {
    return this.getAsync(`/light/api/background-tasks/admin-agent/execution?id=${id}`);
  }

  public async clean(): Promise<IJobLog> {
    return this.deleteAsync(`/light/api/background-tasks/admin-agent/clean`);
  }
}
