import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { featureCollection, point, pointsWithinPolygon } from '@turf/turf';
import { DataService } from '@yaris/core/data.service';
import {
    AttributionType,
    Chat,
    ChatFilter,
    Event,
    EventCategoryType,
    Language,
    Layer,
    LayerConfig,
    LayerSourceType,
    LayerType,
    MSAMapConfig,
    MSAObject,
    MsaObjectProvider,
    NotificationActionType,
    NotificationObjectType,
    RolePermission,
    SecurityType,
    SensitivityLevel,
    Situation,
    UserMapConfig
} from '@yaris/core/domain';
import { ModalService } from '@yaris/core/modal.service';
import { PermissionsService } from '@yaris/core/permissions.service';
import { SocketService } from '@yaris/core/socket.service';
import { WindowsService } from '@yaris/core/windows.service';
import { MsaCreateCircleComponent } from '@yaris/msa/dialogs/msa-create-circle/msa-create-circle.component';
import { MsaCreateObjectComponent } from '@yaris/msa/dialogs/msa-create-object/msa-create-object.component';
import { MsaCreatePolygonComponent } from '@yaris/msa/dialogs/msa-create-polygon/msa-create-polygon.component';
import { MsaCreateTextComponent } from '@yaris/msa/dialogs/msa-create-text/msa-create-text.component';
import { MsaCreateViewLayerComponent } from '@yaris/msa/dialogs/msa-create-view-layer/msa-create-view-layer.component';
import { MsaEditCircleComponent } from '@yaris/msa/dialogs/msa-edit-circle/msa-edit-circle.component';
import { MsaEditObjectComponent } from '@yaris/msa/dialogs/msa-edit-object/msa-edit-object.component';
import { MsaEditPolygonComponent } from '@yaris/msa/dialogs/msa-edit-polygon/msa-edit-polygon.component';
import { MsaEditTextComponent } from '@yaris/msa/dialogs/msa-edit-text/msa-edit-text.component';
import { LayerPanel } from '@yaris/msa/layer-panel.enum';
import ExtendedDataUtil from '@yaris/shared/utils/ExtendedDataUtil';
import * as moment from 'moment';
import { Subject, combineLatest, forkJoin, from, fromEvent, of, timer } from 'rxjs';
import { catchError, concatMap, filter, first, flatMap, map, pluck, scan, takeWhile, tap } from 'rxjs/operators';
import { MsaCreateIncidentComponent } from './dialogs/msa-create-incident/msa-create-incident.component';
import { MsaEditIncidentComponent } from './dialogs/msa-edit-incident/msa-edit-incident.component';
import { UserMapConfigService } from './user-map-config.service';

type LayersMapsActionType = 'add' | 'remove';
export type MSAObjectMap = Omit<MSAObject, 'Layer_id'> & { layerIds: string[] };

export interface LayerProperties {
    hidden: boolean;
    opacity: number;
}

@Injectable({
    providedIn: 'root',
})
export class MsaService {
    private situation: Situation;
    private situationLayers: Layer[] = [];
    private msaObjects: MSAObjectMap[] = [];
    private msaObjectHash: Map<string, MSAObjectMap> = new Map<string, MSAObjectMap>();
    private mapLayerProperties: { [key: string]: LayerProperties } = {};
    private selectedMsaObjects: MSAObjectMap[] = [];
    private selectedMsaObjectsHidden: boolean = false;
    private msaObjectHistory: { [key: string]: MSAObjectMap[] } = {};
    private msaObjectPrediction: { [key: string]: MSAObjectMap[] } = {};
    flagOptions: { label: string; value: string }[] = [];
    private mapLayers: Layer[] = [];
    private mapGroupLayers: Layer[] = [];
    private mapLayersHash: Map<string, Layer> = new Map<string, Layer>();
    private groupLayersHash: Map<string, Layer> = new Map<string, Layer>();
    private readonly msaObjectsDelay = 30;
    private msaObjectsPageLimit = 1000;
    private readonly historyPageDelay = 20;
    private msaMapConfig: MSAMapConfig;
    private chatList: Chat[] = [];
    private readonly opacityDefault = 100;

    private layerPanelSubject = new Subject<LayerPanel>();
    private mapLayerPanelSubject = new Subject<boolean>();
    private situationLayersSubject = new Subject<Layer[]>();
    private msaObjectPropertiesSubject = new Subject<MSAObjectMap>();
    private msaObjectListSubject = new Subject<MSAObjectMap[]>();
    private msaObjectUpdateSubject = new Subject<MSAObject>();
    private selectedMsaObjectsHiddenSubject = new Subject<boolean>();
    private selectedMsaObjectsSubject = new Subject<MSAObjectMap[]>();
    private highlightMsaObjectSubject = new Subject<MSAObjectMap>();
    private msaObjectHistorySubject = new Subject<{ [key: string]: MSAObjectMap[] }>();
    private msaObjectPredictionSubject = new Subject<{ [key: string]: MSAObjectMap[] }>();
    private mapLayerPropertiesSubject = new Subject<{ [key: string]: LayerProperties }>();
    private mapLayersSubject = new Subject<Layer[]>();
    private mapGroupLayersSubject = new Subject<Layer[]>();
    private fitMapToLayerSubject = new Subject<Layer>();
    private fitMapToPolygonSubject = new Subject<MSAObject>();
    private activateRangeMeasureIncidentSubject = new Subject<MSAObject>();
    private activateSelectTracksForCpaObjectSubject = new Subject<void>();
    private selectedTracksForCpaObjectSubject = new Subject<MSAObjectMap[]>();
    private GeofencePerimeterDrawnSubject = new Subject<GeoJSON.Feature<GeoJSON.Polygon>>();
    private activateDrawGeofencePerimeterSubject = new Subject<void>();
    private mapMarkerSubject = new Subject<Event>();
    private notificationrControlSubject = new Subject<{
        NotificationType: string;
        Name: string;
        Added?: string;
        Removed?: string;
        Security: SecurityType;
        Sensitivity: SensitivityLevel;
        Type: LayerType;
        Trespasser?: any;
    }>();
    private msaMapConfigSubject = new Subject<MSAMapConfig>();
    private attributionsSubject = new Subject<{
        attributionKey: AttributionType;
        newState: 'enabled' | 'disabled';
    }>();
    private mapShouldUpdate = false;

    layerPanel$ = this.layerPanelSubject.asObservable();
    mapLayerPanel$ = this.mapLayerPanelSubject.asObservable();
    msaObjectProperties$ = this.msaObjectPropertiesSubject.asObservable();
    msaObjectHumanProperties$ = this.msaObjectPropertiesSubject.asObservable();
    situationLayers$ = this.situationLayersSubject.asObservable();
    msaObjectList$ = this.msaObjectListSubject.asObservable();
    msaObjectUpdate$ = this.msaObjectUpdateSubject.asObservable();
    selectedMsaObjects$ = this.selectedMsaObjectsSubject.asObservable();
    selectedMsaObjectsHidden$ = this.selectedMsaObjectsHiddenSubject.asObservable();
    highlightMsaObject$ = this.highlightMsaObjectSubject.asObservable();
    msaObjectHistory$ = this.msaObjectHistorySubject.asObservable();
    msaObjectPrediction$ = this.msaObjectPredictionSubject.asObservable();
    mapLayerProperties$ = this.mapLayerPropertiesSubject.asObservable();
    mapLayers$ = this.mapLayersSubject.asObservable();
    mapGroupLayers$ = this.mapGroupLayersSubject.asObservable();
    fitMapToLayer$ = this.fitMapToLayerSubject.asObservable();
    fitMapToPolygon$ = this.fitMapToPolygonSubject.asObservable();
    activateRangeMeasureIncident$ = this.activateRangeMeasureIncidentSubject.asObservable();
    activateSelectTracksForCpaObject$ = this.activateSelectTracksForCpaObjectSubject.asObservable();
    selectedTracksForCpaObject$ = this.selectedTracksForCpaObjectSubject.asObservable();
    activateDrawGeofencePerimeter$ = this.activateDrawGeofencePerimeterSubject.asObservable();
    geofencePerimeterDrawn$ = this.GeofencePerimeterDrawnSubject.asObservable();
    mapMarker$ = this.mapMarkerSubject.asObservable();
    notificationControl$ = this.notificationrControlSubject.asObservable();
    msaMapConfig$ = this.msaMapConfigSubject.asObservable();
    attributionsSubject$ = this.attributionsSubject.asObservable();

    // internal
    private updateMapLayers$ = new Subject<{ layers: Layer[]; action: LayersMapsActionType, reload?: boolean }>();

    constructor(
        private socketService: SocketService,
        private modalService: ModalService,
        private dataService: DataService,
        private permissionsService: PermissionsService,
        private windowsService: WindowsService,
        private translateService: TranslateService,
        private userMapConfigService: UserMapConfigService,
        private router: Router,
    ) {
        // MSAObjects notifications
        this.socketService
            .getMSAObjects(() => this.getSituation()._id)
            //.pipe(distinctUntilChanged(this.compareMSAObjects)) //TODO
            .subscribe((object) => {
                this.onMSAObject(object);
            });

        const self = this;
        setInterval(() => {
            if (self.mapShouldUpdate) {
                self.msaObjectListSubject.next(self.listMSAObjects());
                self.mapShouldUpdate = false;
            }
        }, 10000);

        // Layers notifications
        this.updateMapLayers$.subscribe((event) => this.updateMapLayers(event.layers, event.action, event.reload));

        this.msaObjectUpdate$.subscribe((layers) => this.correctSelectedMsaObjects());

        this.situationLayers$.subscribe(() => this.updateMsaMapConfig());

        this.setWindowMessageListener();
        this.setNotificationListeners();
        this.setMSAObjectUpdateListeners();
        this.setUserMapConfigListeners();

        if (this.permissionsService.isLightDomain()) {
            this.msaObjectsPageLimit = 100;
        }

        this.setFlagOptions();
    }

    getFlagOptions() {
        return this.flagOptions;
    }

    /**
     * Request an updated chat list from the dataService.
     */
    getChatList(): void {
        this.dataService
            .listChats(ChatFilter.NotHidden, this.situation._id, undefined, undefined)
            .subscribe((chatList) => (this.chatList = chatList));
    }

    /**
     * Check if there's a chat for the given MSAObject id.
     * @param msaObjectId The MSAObject ID.
     * @returns Boolean value indicating if chat was found or not.
     */
    msaObjectHasChat(msaObjectId: string): boolean {
        return this.chatList.some((chat) => chat.MSAObject_id === msaObjectId);
    }

    /**
     * Get the chat for the given MSAObject id.
     * @param msaObjectId The MSAObject id.
     * @returns That Chat object if found.
     */
    getChatForMSAObject(msaObjectId: string): Chat {
        return this.chatList.find((chat) => chat.MSAObject_id === msaObjectId);
    }

    setSituation(situation: Situation): void {
        if (!this.situation) {
            this.situation = situation;
            this.socketService.joinToRoom({ Situations: [this.situation._id] });
            this.userMapConfigService.setSituation(this.situation);
            this.dataService.listSituationLayers(situation._id).subscribe((layers) => {
                this.situationLayers = layers.sort((a, b) => this.sortSituationLayers(a, b));
                this.situationLayersSubject.next(this.listSituationLayers());
            });
            return;
        }

        this.situation = situation;
        if (this.situation.Layers_ids.some((id) => !this.situationLayers.find((l) => l._id === id))) {
            this.dataService.listSituationLayers(situation._id).subscribe((layers) => {
                this.situationLayers = layers.sort((a, b) => this.sortSituationLayers(a, b));
                this.situationLayersSubject.next(this.listSituationLayers());
            });
            return;
        }
        // Just a removal, remove locally
        if (this.situationLayers.length > this.situation.Layers_ids.length) {
            this.situationLayers = this.situationLayers
                .filter((l) => this.situation.Layers_ids.includes(l._id))
                .sort((a, b) => this.sortSituationLayers(a, b));
            this.situationLayersSubject.next(this.listSituationLayers());
            return;
        }
        // Just a reordering, sort locally.
        this.situationLayers.sort((a, b) => this.sortSituationLayers(a, b));
        this.situationLayersSubject.next(this.listSituationLayers());
    }

    getMapLayers() {
        return this.mapLayers;
    }

    getSituation(): Situation {
        return { ...this.situation };
    }

    listSituationLayers(): Layer[] {
        return this.situationLayers.slice();
    }

    listMapLayers(): Layer[] {
        return this.mapLayers.slice();
    }

    getSituationGeneralLayer() {
        const situationId = this.getSituation()._id;
        return this.situationLayers.find(
            (layer) => layer.DefaultFromSituation === situationId && layer.LayerType === LayerType.Points,
        );
    }

    getMSAObject(id: any): MSAObjectMap {
        return this.msaObjectHash.get(id);
    }

    listMSAObjects(): MSAObjectMap[] {
        return this.msaObjects; //.slice();
    }

    listMSAObjectsByLayer(layerId: string): MSAObjectMap[] {
        return this.msaObjects.filter((o) => o.layerIds.includes(layerId));
    }

    listMSAObjectsPoints(): MSAObjectMap[] {
        return this.listMSAObjects().filter((o) => o.Geometry.Type === 'Point');
    }

    listSelectedMSAObjects(): MSAObjectMap[] {
        return this.selectedMsaObjects.slice();
    }

    isSelectedMsaObjectsHidden(): boolean {
        return this.selectedMsaObjectsHidden;
    }

    situationHasLayer(layer: Layer): boolean {
        if (!this.situation) {
            return false;
        }
        return this.situation.Layers_ids.includes(layer._id);
    }

    mapHasLayer(layer: Layer): boolean {
        if (layer.GroupLayer) {
            return !!this.groupLayersHash.get(layer?._id);
        } else {
            const layerValue = this.mapLayersHash.get(layer?._id);
            return !!layerValue; // (is this validation important?) && layerValue.LayerParents?.includes(this.defaultParentId);
        }
    }

    hasMapLayerProperties(layer: Layer): boolean {
        return !!this.mapLayerProperties[layer._id];
    }

    listMapLayerProperties(layer: Layer): LayerProperties {
        if (!this.mapLayerProperties[layer._id]) {
            return {
                hidden: false,
                opacity: 100,
            };
        }
        return { ...this.mapLayerProperties[layer._id] };
    }

    listAllMapLayerProperties(): { [key: string]: LayerProperties } {
        return { ...this.mapLayerProperties };
    }

    hasMsaObjectHistory(msaObject: MSAObjectMap): boolean {
        return !!this.msaObjectHistory[msaObject._id];
    }

    hasMsaObjectPrediction(msaObject: MSAObjectMap): boolean {
        return !!this.msaObjectPrediction[msaObject._id];
    }

    listMsaObjectHistory(msaObject: MSAObjectMap): MSAObjectMap[] {
        if (!this.msaObjectHistory[msaObject._id]) {
            return [];
        }
        return this.msaObjectHistory[msaObject._id].slice();
    }

    listMsaObjectPrediction(msaObject: MSAObjectMap): MSAObjectMap[] {
        if (!this.msaObjectPrediction[msaObject._id]) {
            return [];
        }
        return this.msaObjectPrediction[msaObject._id].slice();
    }

    listAllMsaObjectHistory(): { [key: string]: MSAObjectMap[] } {
        return { ...this.msaObjectHistory };
    }

    listAllMsaObjectPrediction(): { [key: string]: MSAObjectMap[] } {
        return { ...this.msaObjectPrediction };
    }

    getMsaMapConfig(): MSAMapConfig {
        return { ...this.msaMapConfig };
    }

    updateVesselNameDisplay(newVesselNameDisplay: string[]) {
        this.msaMapConfig = {
            ...this.msaMapConfig,
            VesselNameDisplay: newVesselNameDisplay,
        };

        this.msaMapConfigSubject.next(this.getMsaMapConfig());
    }

    updateCopyrightAttributionState(attributionKey: AttributionType, newState: 'enabled' | 'disabled') {
        this.attributionsSubject.next({ attributionKey, newState });
    }

    layerPanel(panel: LayerPanel): void {
        this.layerPanelSubject.next(panel);
    }

    mapLayerPanel(expand: boolean): void {
        this.mapLayerPanelSubject.next(expand);
    }

    addLayerToSituation(layer: Layer): void {
        if (!this.situation || this.situationHasLayer(layer)) {
            return;
        }
        const layerIds = this.situation.Layers_ids.concat(layer._id);
        this.dataService.updateOperationalSituationLayers({ ...this.situation, Layers_ids: layerIds }).subscribe({
            next: (situation) => {
                this.situationLayers = this.situationLayers.concat(layer).sort((a, b) => this.sortSituationLayers(a, b));
                this.situationLayersSubject.next(this.listSituationLayers());
            },
            error: (error) => alert(error),
        });
    }

    removeLayerFromSituation(layer: Layer): void {
        if (!this.situation || !this.situationHasLayer(layer)) {
            return;
        }
        const layerIds = this.situation.Layers_ids.filter((id) => id !== layer._id);
        this.dataService
            .updateOperationalSituationLayers({ ...this.situation, Layers_ids: layerIds }, layer._id)
            .subscribe({
                next: (situation) => {
                    this.situationLayers = this.situationLayers.filter((l) => l._id !== layer._id);
                    this.situationLayersSubject.next(this.listSituationLayers());
                },
                error: (error) => alert(error),
            });
    }

    addLayerToMap(layer: Layer, shared: boolean = false): void {
        if (!layer || this.mapHasLayer(layer)) {
            return;
        }

        this.dataService.getLayer(layer._id, shared).subscribe(
            (layerDetail) => {
                this.updateMapLayers$.next({ layers: [{ ...layerDetail }], action: 'add' });
            },
            (err) => {
                this.removeLayerFromMap(layer);
            },
        );
    }

    removeLayerFromMap(layer: Layer): void {
        if (layer || this.mapHasLayer(layer)) {
            this.showLayer(layer);
            this.updateMapLayers$.next({ layers: [layer], action: 'remove' });
        }
    }


  isAreaZEELayer(layer: Layer): boolean {
    return layer.Name.includes('Area - ZEE') && layer.IsDefault;
  }

    removeGroupLayerFromMap(layer: Layer): void {
        const isParentLayer: boolean = layer?.GroupLayer && layer?.Layers?.length === 2;

        if (isParentLayer) {
            const childLayers: Layer[] = this.mapLayers.filter((mapLayer) => layer.Layers.includes(mapLayer._id));
            childLayers.forEach((childLayer) => this.showLayer(childLayer));

            this.updateMapLayers$.next({ layers: [layer], action: 'remove' });
        } else {
            forkJoin(layer.LayerParents.map((parentLayer) => this.dataService.getLayer(parentLayer))).subscribe(
                (parentLayers) => {
                    if (!parentLayers) {
                        return;
                    }

                    parentLayers.forEach((parentLayer) => {
                        parentLayer.GroupChildrenLayers.forEach((childLayer) => {
                            this.showLayer(childLayer);
                        });
                    });

                    this.updateMapLayers$.next({ layers: parentLayers, action: 'remove' });
                },
            );
        }
    }

    removeAllLayersFromMap(): void {
        this.updateMapLayers$.next({ layers: this.mapLayers, action: 'remove' });
    }

    moveLayerDown(layer: Layer): void {
        if (!this.mapHasLayer(layer)) {
            return;
        }
        if (this.mapLayers.length < 2) {
            return;
        }

        const underLayer = this.mapLayers.find((l) => l.Order == layer.Order + 1);

        underLayer.Order--;
        layer.Order++;

        this.mapLayers = Array.from(this.mapLayersHash.values()).sort((a, b) => a.Order - b.Order);
        this.mapLayersSubject.next(this.mapLayers);
    }

    showObjectProperties(msaObject: MSAObjectMap): void {
        this.msaObjectPropertiesSubject.next(msaObject);
    }

    hideLayer(layer: Layer): void {
        this.mapLayerProperties[layer._id].hidden = true;
        this.mapLayerPropertiesSubject.next(this.listAllMapLayerProperties());
    }

    showLayer(layer: Layer): void {
        this.mapLayerProperties[layer._id].hidden = false;
        this.mapLayerPropertiesSubject.next(this.listAllMapLayerProperties());
    }

    toggleLayerVisibility(layer: Layer): void {
        if (this.mapLayerProperties[layer._id].hidden) {
            this.showLayer(layer);
            return;
        }
        this.hideLayer(layer);
    }

    setLayerOpacity(layer: Layer, opacity: number): void {
        if (!this.mapLayerProperties[layer._id]) {
            this.mapLayerProperties[layer._id] = { hidden: false, opacity: this.opacityDefault };
        }
        this.mapLayerProperties[layer._id].opacity = opacity;
        this.mapLayerPropertiesSubject.next(this.listAllMapLayerProperties());
    }

    createMsaObject(layers: Layer[], fromEvent?: Situation, coordinates?: number[]): void {
        this.modalService
            .open({
                closable: true,
                inputs: { layers, coordinates, fromEvent },
                title: 'New point',
                contentComponent: MsaCreateObjectComponent,
            })
            .error.subscribe({ error: (error) => alert(error) });
    }

    createtextObject(layers: Layer[], fromEvent?: Situation, coordinates?: number[]): void {
        this.modalService
            .open({
                closable: true,
                inputs: { layers, coordinates, fromEvent },
                title: 'New text',
                contentComponent: MsaCreateTextComponent,
            })
            .error.subscribe({ error: (error) => alert(error) });
    }

    createIncident(title: string, layers: Layer[], ev: any[]) {
        this.modalService.open({
            closable: true,
            title: title,
            inputs: { coordinates: ev, layers: layers, situationId: this.situation._id, type: 'Polygon' },
            contentComponent: MsaCreateIncidentComponent,
        });
    }

    createPolygonObject(layers: Layer[], coordinates: {}[], type: string, dashed?: boolean): void {
        const title = type === 'Polygon' ? 'New Polygon' : 'New Line';
        this.modalService
            .open({
                closable: true,
                inputs: { layers, coordinates, type, dashed },
                title: title,
                contentComponent: MsaCreatePolygonComponent,
            })
            .error.subscribe({ error: (error) => console.error(error) });
    }

    createCircleObject(layers: Layer[], coordinates: {}[]): void {
        this.modalService
            .open({
                closable: true,
                inputs: { layers, coordinates, type: 'Polygon' },
                title: 'New Circle',
                contentComponent: MsaCreateCircleComponent,
            })
            .error.subscribe({ error: (error) => console.error(error) });
    }

    removeObjectFromLayer(msaObject: MSAObjectMap, layer: Layer): void {
        this.dataService
            .removeMSAObjectFromLayer(msaObject._id, layer._id)
            .subscribe({ error: (error) => console.error(error) });
    }

    deleteMSAObject(msaObject: MSAObject): void {
        this.dataService.deleteMSAObject(msaObject._id).subscribe({ error: (error) => console.error(error) });
    }

    deleteIncident(incident): void {
        this.dataService.deleteIncident(incident._id).subscribe({ error: (error) => console.log(error) });
    }

    private updateLayer(layer: Layer): void {
        const foundLayer = this.mapLayers.find((l) => l._id === layer._id);
        if (foundLayer) Object.assign(foundLayer, layer);
    }

    addObjectsToLayer(msaObjects: MSAObjectMap[], layer: Layer): void {
        const msaObjectIds = msaObjects.map((o) => o._id);
        for (const msaObjectId of msaObjectIds) {
            this.dataService.addMSAObjectToLayer(msaObjectId, layer._id).subscribe({ error: (error) => alert(error) });
        }
    }

    isGroupLayer(layer: Layer): boolean {
        return layer.hasOwnProperty('ZeeIdentifier');
    }

    isGeneralLayer(layer: Layer): boolean {
        return !!layer?.DefaultFromSituation;
    }

    isZEELayer(layer: Layer): boolean {
        return layer?.Name?.includes('ZEE') && layer?.IsDefault;
    }

    isPoint(msaObject: MSAObject | MSAObjectMap): boolean {
        return msaObject?.Geometry?.Type === 'Point';
    }

    isPort(msaObject: MSAObject | MSAObjectMap): boolean {
        return msaObject?.geometryFileURL?.includes('ports') || msaObject?.Properties?.LOCODE
    }

    isVideo(msaObject: MSAObject | MSAObjectMap): boolean {
        return msaObject?.Icon?.includes('video-camera.png') && msaObject?.Properties?.Subtype === 'stream';
      }

    isLabel(msaObject: MSAObjectMap): boolean {
        return msaObject?.Properties?.Label?.length > 0;
    }

    isIncidentWarning(msaObject: MSAObjectMap): boolean {
        return !!msaObject?.Properties?.IsIncidentWarning;
    }

    isIncident(msaObject: MSAObjectMap): boolean {
        return msaObject?.Properties?.EventCategory == EventCategoryType.Incident;
    }


    canWriteIncident(msaObject: MSAObjectMap): boolean {
        if (this.isIncident(msaObject)) {
          return this.permissionsService.hasRolePermissionEqualOrAbove(RolePermission.operator);
        }
        if (this.isIncidentWarning(msaObject)) {
          return this.permissionsService.hasRolePermissionEqual(RolePermission.operations_manager);
        }

        return false;
      }

    isCircle(msaObject: MSAObjectMap): boolean {
        return !!msaObject?.Geometry?.Radius;
    }

    isHistory(msaObject: MSAObjectMap): boolean {
        return !msaObject._id && !!msaObject.MSAObject_id;
    }

    isSkylight(msaObject: MSAObjectMap) {
        return this.isPoint(msaObject) && msaObject.Provider === MsaObjectProvider.SKYLIGHT;
    }

    skylightVesselsHasAIS(msaObject: MSAObjectMap) {
    return msaObject?.Properties?.vessels?.some((v) => v.Providers?.includes(MsaObjectProvider.AIS));
    }

    editMsaObject(msaObject: MSAObjectMap) {
        if (this.isIncidentWarning(msaObject) || this.isIncident(msaObject)) {
            this.editIncident(msaObject);
        } else if (this.isCircle(msaObject)) {
            this.editCircleObject(msaObject);
        } else if (this.isLabel(msaObject)) {
            this.editMsaObjectLabel(msaObject);
        } else if (this.isPoint(msaObject)) {
            this.editMsaObjectPoint(msaObject);
        } else {
            this.editPolygonObject(msaObject);
        }
    }

    editMsaObjectPoint(msaObject: MSAObject | MSAObjectMap): void {
        let hasPermission = false;
        let layer: Layer;
        if(this.isVideo(msaObject)) return;
        if ((msaObject as MSAObject).Layer_id) {
            layer = this.mapLayers.find((l) => l._id === (msaObject as MSAObject).Layer_id);
            hasPermission = this.permissionsService.getMSALayerAccessRights(layer).modify.write;
        } else if ((msaObject as MSAObjectMap)?.layerIds?.length) {
            layer = this.mapLayers.find(
                (l) =>
                    (msaObject as MSAObjectMap).layerIds.includes(l._id) &&
                    this.permissionsService.getMSALayerAccessRights(l).modify.write,
            );
            if (layer) hasPermission = true;
        }

        if (hasPermission)
            this.modalService
                .open({
                    closable: true,
                    inputs: { msaObject: msaObject, layer: layer, type: msaObject.Geometry.Type },
                    title: msaObject.Name ? msaObject.Name : msaObject.Properties.Name || 'Unnamed',
                    contentComponent: this.getWindowComponentForMSAObject(msaObject),
                    exclusive: true,
                })
                .error.subscribe({ error: (error) => alert(error) });
    }

    getWindowComponentForMSAObject(msaObject: MSAObject | MSAObjectMap) {
        const geometry = msaObject?.Geometry;
        const icon = msaObject?.Icon;
        if (geometry?.Type === 'Point') {
          if (icon === undefined){
            return MsaEditTextComponent
          } else {
            return MsaEditObjectComponent;
          }
        } else if (geometry?.Type === 'Polygon') {
          if (geometry?.Center.length > 0) {
            return MsaEditCircleComponent;
          } else {
            return MsaEditPolygonComponent;
          }
        } else {
          return MsaEditPolygonComponent;
        }
      }

      isMSAObjectAnImageType(msaObject: MSAObject | MSAObjectMap): boolean {
        return msaObject.FillImage && msaObject.FillImageOpacity ? true : false;
      }

    editMsaObjectLabel(msaObject: MSAObjectMap): void {
        const layer = this.mapLayers.find((l) => msaObject.layerIds.includes(l._id));

        if (this.permissionsService.getMSALayerAccessRights(layer).modify.write)
            this.modalService
                .open({
                    closable: true,
                    inputs: { msaObject: msaObject, layer: layer },
                    title: msaObject.Properties.Label || 'Unnamed',
                    contentComponent: MsaEditTextComponent,
                    exclusive: true,
                })
                .error.subscribe({ error: (error) => alert(error) });
    }

    editMsaObjectInformationLayer(msaObject: MSAObjectMap): void {
        const layer = this.mapLayers.find(
            (l) => msaObject.layerIds.includes(l._id) && l.LayerType == LayerType.Information,
        );
        if (!layer) {
            return;
        }
        this.modalService
            .open({
                closable: true,
                inputs: { msaObject: msaObject, layer: layer },
                title: msaObject.Name ? msaObject.Name : msaObject.Properties.Name || 'Unnamed',
                contentComponent: MsaEditObjectComponent,
                exclusive: true,
            })
            .error.subscribe({ error: (error) => alert(error) });
    }

    editPolygonObject(msaObject: MSAObjectMap): void {
        const layer = this.mapLayers.find((l) => msaObject.layerIds.includes(l._id));
        if (layer.LayerType !== LayerType.Points) {
            return;
        }
        this.modalService
            .open({
                closable: true,
                inputs: { msaObject: msaObject, layer: layer, type: msaObject.Geometry.Type },
                title: msaObject.Properties.Name || 'Unnamed',
                contentComponent: MsaEditPolygonComponent,
                exclusive: true,
            })
            .error.subscribe({ error: (error) => alert(error) });
    }

    editCircleObject(msaObject: MSAObjectMap): void {
        const layer = this.mapLayers.find((l) => msaObject.layerIds.includes(l._id));
        if (layer.LayerType !== LayerType.Points) {
            return;
        }
        this.modalService
            .open({
                closable: true,
                inputs: { msaObject: msaObject, layer: layer, type: 'Circle' },
                title: msaObject.Properties.Name || 'Unnamed',
                contentComponent: MsaEditCircleComponent,
                exclusive: true,
            })
            .error.subscribe({ error: (error) => alert(error) });
    }

    editIncident(msaObject: MSAObjectMap): void {
        if ( !this.canWriteIncident(msaObject) ) return;

        const layer = this.mapLayers.find((l) => msaObject.layerIds.includes(l._id));

        this.modalService
            .open({
                closable: true,
                inputs: { incident: msaObject, layer: layer, type: 'Polygon', goToManageIncidentsAfterSubmit: false },
                title: msaObject.Properties.Name || 'Unnamed',
                contentComponent: MsaEditIncidentComponent,
                exclusive: true,
            })
            .error.subscribe({ error: (error) => alert(error) });
    }

    selectMsaObjects(msaObjects: MSAObjectMap[]): void {
        this.selectedMsaObjects = this.selectedMsaObjects
            .filter((object) => !msaObjects.find((o) => o._id === object._id))
            .concat(msaObjects)
            .filter((object) => !!this.msaObjects.find((o) => o._id === object._id));

        this.selectedMsaObjectsSubject.next(this.listSelectedMSAObjects());
    }

    unselectMsaObjects(msaObjects: MSAObject[]): void {
        const unselect = msaObjects.map((o) => o._id);
        this.selectedMsaObjects = this.selectedMsaObjects.filter((object) => !unselect.includes(object._id));

        this.selectedMsaObjectsSubject.next(this.listSelectedMSAObjects());
    }

    clearSelectedMsaObjects(): void {
        this.selectedMsaObjects = [];

        this.selectedMsaObjectsHidden = false;

        this.selectedMsaObjectsSubject.next(this.listSelectedMSAObjects());
        this.selectedMsaObjectsHiddenSubject.next(this.isSelectedMsaObjectsHidden());
    }

    toggleSelectedMsaObjectsVisibility(): void {
        this.selectedMsaObjectsHidden = !this.selectedMsaObjectsHidden;
        this.selectedMsaObjectsHiddenSubject.next(this.isSelectedMsaObjectsHidden());
    }

    createViewLayer(msaObjects: MSAObjectMap[]): void {
        this.modalService
            .open({
                closable: true,
                inputs: { msaObjects: msaObjects },
                title: this.translateService.instant('MSA.NEWVIEWLAYERFROMSELECTION'),
                contentComponent: MsaCreateViewLayerComponent,
            })
            .error.subscribe({ error: (error) => alert(error) });
    }

    highlightMsaObject(msaObject: MSAObjectMap): void {
        this.highlightMsaObjectSubject.next(msaObject);
    }

    getMsaObjectHistory(msaObject: MSAObjectMap, date: Date, interval: number = 1): void {
        delete this.msaObjectHistory[msaObject._id];

        const msaobject_id = msaObject._id;
        let end = new Date();
        let period = date;

        timer(0, this.historyPageDelay)
            .pipe(
                tap((p) => {
                    end = moment
                        .utc(end)
                        .subtract(moment.duration(3 * p, 'h'))
                        .toDate();
                    period = moment
                        .utc(period)
                        .subtract(moment.duration(3 * p + 1, 'h'))
                        .toDate();
                }),
            )
            .pipe(
                concatMap((p) => {
                    if (msaObject.Type) {
                        return this.dataService.listSkylightHistory(msaobject_id, interval);
                    }

                    return this.dataService.listMSAObjectHistory(msaobject_id, period.toISOString(), end.toISOString());
                }),
            )
            .pipe(pluck('data'))
            .pipe(takeWhile((data) => period >= date, true))
            .pipe(pluck('docs'))
            .pipe(filter((h) => !!h))
            .pipe(scan((acc, h) => h.concat(acc), []))
            .pipe(map((history) => history.sort(this.sortMSAObjectHistory)))
            .subscribe((history) => {
                if (history.length < 1) {
                    Array.from(document.getElementsByClassName('maplibregl-canvas')).forEach((e: any) => {
                        e.classList.remove('loading');
                    });
                    this.msaObjectHistorySubject.next(this.listAllMsaObjectHistory());
                    return;
                }

                history.pop();

                this.msaObjectHistory[msaObject._id] = [...history, msaObject] as MSAObjectMap[];
                Array.from(document.getElementsByClassName('maplibregl-canvas')).forEach((e: any) => {
                    e.classList.remove('loading');
                });
                this.msaObjectHistorySubject.next(this.listAllMsaObjectHistory());
            });
    }
    getMsaObjectPrediction(msaObject: MSAObjectMap, time: number, date: Date, missingSOGOrCOG): void {
        if (missingSOGOrCOG !== undefined) return;
        this.clearMsaObjectPrediction(msaObject);
        const end = msaObject.Properties.PositionUtc ? new Date(msaObject.Properties.PositionUtc) : new Date();
        const defaultPeriod = 2 * 60; // 120min
        const period = date === undefined ? defaultPeriod : Math.abs(date?.getTime() - end.getTime()) / (60 * 1000);

        const predictionCoordinates: [number, number][] = [
            msaObject.Geometry.Coordinates,
            this.calculate_future_position(
                msaObject.Geometry.Coordinates,
                Number(msaObject.Properties.SOG),
                Number(msaObject.Properties.COG),
                60 / time,
            ),
        ];
        const dates: any[] = [end.toISOString(), this.addMinutes(end, time)];
        const prediction = [];
        const temp = msaObject;
        const iter: number = period / time; //-((period/30)%1)
        const decimals = iter % 1;

        for (let i = 1; i < iter; i++) {
            predictionCoordinates.push(
                this.calculate_future_position(
                    predictionCoordinates[predictionCoordinates.length - 1],
                    Number(msaObject.Properties.SOG),
                    Number(msaObject.Properties.COG),
                    60 / time,
                ),
            );
            dates.push(this.addMinutes(new Date(msaObject.Properties.PositionUtc), (i + 1) * time));
        }
        if (decimals !== 0 && iter > 0) {
            predictionCoordinates.push(
                this.calculate_future_position(
                    predictionCoordinates[predictionCoordinates.length - 1],
                    Number(msaObject.Properties.SOG),
                    Number(msaObject.Properties.COG),
                    2 / decimals,
                ),
            );
            dates.push(this.addMinutes(new Date(msaObject.Properties.PositionUtc), iter * time));
        }
        predictionCoordinates.forEach((coor, index) => {
            const temp = {
                Geometry: { Coordinates: coor, Type: 'Point' },
                MSAObject_id: msaObject._id,
                Properties: { ...msaObject.Properties, PositionUtc: dates[index] },
                Color: coor === msaObject.Geometry.Coordinates ? msaObject.Color : 'rgba(255,255,255,1)',
                Icon: coor === msaObject.Geometry.Coordinates ? msaObject.Icon : '/assets/images/map/mark.png',
            };
            prediction.push(temp);
        });
        this.msaObjectPrediction[msaObject._id] = [...prediction, msaObject] as MSAObjectMap[];
        this.msaObjectPredictionSubject.next(this.listAllMsaObjectPrediction());
    }

    calculate_future_position(
        point_a: [number, number],
        vessel_speed: number,
        vector_angle: number,
        factor: number,
    ): [number, number] {
        vessel_speed = (vessel_speed / factor) * 1.852;

        //vector_angle = vector_angle > 180?-vector_angle+180:vector_angle
        const lat1 = (point_a[1] * Math.PI) / 180.0;
        const lon1 = (point_a[0] * Math.PI) / 180.0;
        vector_angle = (vector_angle * Math.PI) / 180.0;

        const lat2 = Math.asin(
            Math.sin(lat1) * Math.cos(vessel_speed / 6378.137) +
            Math.cos(lat1) * Math.sin(vessel_speed / 6378.137) * Math.cos(vector_angle),
        );
        const lon2 =
            lon1 +
            Math.atan2(
                Math.sin(vector_angle) * Math.sin(vessel_speed / 6378.137) * Math.cos(lat1),
                Math.cos(vessel_speed / 6378.137) - Math.sin(lat1) * Math.sin(lat2),
            );
        return [(lon2 * 180) / Math.PI, (lat2 * 180) / Math.PI];
    }

    addMinutes(date, minutes) {
        return new Date(date.getTime() + minutes * 60000).toISOString();
    }
    clearMsaObjectHistory(msaObject: MSAObjectMap): void {
        delete this.msaObjectHistory[msaObject._id];
        this.msaObjectHistorySubject.next(this.listAllMsaObjectHistory());
    }

    clearMsaObjectPrediction(msaObject: MSAObjectMap): void {
        delete this.msaObjectPrediction[msaObject._id];
        this.msaObjectPredictionSubject.next(this.listAllMsaObjectPrediction());
    }

    fitMapToLayer(layer: Layer): void {
        if (!this.mapLayers.find((l) => l._id === layer._id)) {
            return;
        }
        this.fitMapToLayerSubject.next(layer);
    }

    fitMapToPolygon(msaObject: MSAObject): void {
        this.fitMapToPolygonSubject.next(msaObject);
    }

    activateRangeMeasureIncident(): void {
        this.activateRangeMeasureIncidentSubject.next();
    }

    activateSelectTracksForCpaObject(): void {
        this.activateSelectTracksForCpaObjectSubject.next();
    }

    activateDrawGeofencePerimeter(): void {
        this.activateDrawGeofencePerimeterSubject.next();
    }

    setGeofencePerimeterDrawn(polygon: GeoJSON.Feature<GeoJSON.Polygon>): void {
        this.GeofencePerimeterDrawnSubject.next(polygon);
    }

    setSelectedTracksForCpa(tracks: MSAObjectMap[]) {
        if (tracks?.length === 2 && tracks.every((track) => track.Geometry.Type === 'Point'))
            this.selectedTracksForCpaObjectSubject.next(tracks);
    }

    filterObjectsInsideGeofence(geofence: GeoJSON.Feature<GeoJSON.Polygon>): void {
        const selectedMsaObjects = featureCollection(
            this.selectedMsaObjects.map((msaObject) => point(msaObject.Geometry.Coordinates, { id: msaObject._id })),
        ) as any;
        const pointsWithin = pointsWithinPolygon(selectedMsaObjects, geofence).features.map((point) => point.properties.id);
        const objectToSelect = this.listMSAObjects().filter((o) => pointsWithin.includes(o._id));
        this.clearSelectedMsaObjects();
        this.selectMsaObjects(objectToSelect);
    }

    geoWithin(msaObjects: MSAObject[], geoQuery: GeoJSON.Feature<GeoJSON.Polygon>): MSAObject[] {
        const msaObjectsAsFeatures = msaObjects.map((msaObject) =>
            point(msaObject.Geometry.Coordinates, { id: msaObject._id }),
        );
        const inside = pointsWithinPolygon(featureCollection(msaObjectsAsFeatures), geoQuery).features.map(
            (point) => point.properties.id,
        );
        return msaObjects.filter((msaObject) => inside.includes(msaObject._id));
    }

    /**
     * Check if layer exists
     * @param {string[]} id - The layer ID
     * @returns {boolean} Boolean indicating if a layer was found or not
     */
    hasLayer(id: string): boolean {
        return this.getMapLayers().some((layer) => layer._id === id);
    }

    openEvent(id: string): void {
        this.windowsService.openEventOnLOGWindow(this.situation._id, id);
    }

    private updateMsaMapConfig() {
        this.dataService.getMSAMapConfig().subscribe((msaMapConfig) => {
            this.msaMapConfig = msaMapConfig;
            this.msaMapConfigSubject.next(this.msaMapConfig);
        });
    }

    private correctSelectedMsaObjects(): void {
        this.selectedMsaObjects = this.msaObjects.filter((o) => !!this.selectedMsaObjects.find((s) => s._id === o._id));
    }

    private correctMsaObjectHistory(): void {
        Object.keys(this.msaObjectHistory)
            .filter((h) => !this.msaObjects.find((o) => h === o._id))
            .forEach((h) => delete this.msaObjectHistory[h]);
    }

    defaultParentId = 'xxx';

    private addLayerToMapHash(layer: Layer, parentLayer?: Layer): Layer[] {
        const layersToAddObjects: Layer[] = [];

        const layerOnHash = this.mapLayersHash.get(layer._id);
        if (layerOnHash) {
            layerOnHash.LayerParents.push(parentLayer ? parentLayer._id : this.defaultParentId);
            layerOnHash.LayerParents = [...new Set(layerOnHash.LayerParents)];
            layer.LayerParents = layerOnHash.LayerParents;
        } else {
            const currentLayers = Array.from(this.mapLayersHash.values());
            const lastOrder = currentLayers?.length
                ? Math.max.apply(
                    Math,
                    currentLayers.map((l) => l.Order),
                )
                : -1;

            layer.LayerParents = [parentLayer ? parentLayer._id : this.defaultParentId];
            layer.LayerType = layer.SourceType == 'Skylight' ? LayerType.Information : layer.LayerType;
            layer.Order = lastOrder + 1;
            this.mapLayersHash.set(layer._id, layer);
            layersToAddObjects.push(layer);
        }

        if (parentLayer) {
            this.groupLayersHash.set(parentLayer._id, parentLayer);
        }

        return layersToAddObjects;
    }

    private removeLayerToMapHash(childId: string, parentLayerId?: string) {
        const layersToRemoveObjects: Layer[] = [];

        const layerOnHash = this.mapLayersHash.get(childId);
        if (layerOnHash) {
            layerOnHash.LayerParents = [
                ...new Set(layerOnHash.LayerParents.filter((id) => id != (parentLayerId ?? this.defaultParentId))),
            ];

            if (!layerOnHash.LayerParents.length) {
                this.mapLayersHash.delete(childId);
                layersToRemoveObjects.push(layerOnHash);
            }
        }

        if (parentLayerId) {
            const groupLayerOnHash = this.groupLayersHash.get(parentLayerId);
            if (groupLayerOnHash) {
                this.groupLayersHash.delete(parentLayerId);
            }
        }

        return layersToRemoveObjects;
    }

    private updateMapLayers(layersToChange: Layer[], action: LayersMapsActionType, reload: boolean = false): void {
        const layersToAdd: Layer[] = [];
        const layersToRemove: Layer[] = [];

        for (const layer of layersToChange) {
            if (action == 'add') {
                if (layer.GroupLayer) {
                    for (const childLayer of layer.GroupChildrenLayers) {
                        layersToAdd.push(...this.addLayerToMapHash(childLayer, layer));
                    }
                } else {
                    layersToAdd.push(...this.addLayerToMapHash(layer));
                }
            } else if (action == 'remove') {
                if (layer.GroupLayer) {
                    for (const childLayerId of layer.Layers) {
                        layersToRemove.push(...this.removeLayerToMapHash(childLayerId, layer._id));
                    }
                } else {
                    layersToRemove.push(...this.removeLayerToMapHash(layer._id));
                }
            }
        }

        if (layersToRemove.length) {
            this.socketService.leaveRoom({ Layers: layersToRemove.map((l) => l._id) });
        }

        if (layersToAdd.length) {
            this.socketService.joinToRoom({ Layers: layersToAdd.map((l) => l._id) });
        }

        this.mapLayers = Array.from(this.mapLayersHash.values()).sort((a, b) => a.Order - b.Order);
        this.mapGroupLayers = Array.from(this.groupLayersHash.values());

        this.checkCopyrightAttributions(this.mapLayers);
        this.socketService.activeLayers = this.mapLayers.map((l) => l._id);
        this.mapLayersSubject.next(this.mapLayers);
        this.mapGroupLayersSubject.next(this.mapGroupLayers);

        this.updateLayersProperties(layersToChange.concat(layersToAdd));

        if (action == 'add') {
            if (reload) {
              this.updateLayers(layersToChange, action);
            } else {
              this.updateLayers(layersToAdd, action);
            }
        } else if (action == 'remove') {
            this.updateLayers(layersToRemove, action);
        }
    }

    private updateLayersProperties(layers: Layer[]) {
        layers
            .filter((l) => !this.hasMapLayerProperties(l))
            .forEach((l) => (this.mapLayerProperties[l._id] = { hidden: false, opacity: this.opacityDefault }));

        this.mapLayerPropertiesSubject.next(this.listAllMapLayerProperties());
    }

    private setMapLayerPropertiesFromUserMapConfig(userMapConfig: UserMapConfig) {
        const mapLayerProperties = {};
        userMapConfig.Layers.forEach((layerConfig) => {
            mapLayerProperties[layerConfig.LayerId] = {
                hidden: layerConfig.IsHidden,
                opacity: layerConfig.Opacity || this.opacityDefault,
            };
        });
        this.mapLayerProperties = mapLayerProperties;
    }

    updateLayers(layers: Layer[], action: LayersMapsActionType) {
        if (action == 'add') {
            from(layers)
                .pipe(
                    filter((l) => !l.InternalWMS),
                    flatMap((layer) =>
                        timer(0, this.msaObjectsDelay)
                            .pipe(map((p) => p + 1))
                            .pipe(
                                concatMap((p) => {
                                    return this.dataService.listLayerMsaObjects(
                                        layer._id,
                                        this.situation._id,
                                        layer.LayerParents?.find((id) => id != this.defaultParentId),
                                        this.msaObjectsPageLimit,
                                        p,
                                    );
                                }),
                            )
                            .pipe(pluck('data'))
                            .pipe(takeWhile((data) => data.page < data.pages, true))
                            .pipe(pluck('docs')),
                    ),
                )
                .pipe(scan((acc, msaObjects) => msaObjects.concat(acc), []))
                .subscribe((msaObjects: MSAObject[]) => {
                    this.handleReceivedMSA(msaObjects.map((o) => ({ ...o, layerIds: [o.Layer_id], Layer_id: null })));
                });
        }

        if (action == 'remove') {
            const layerIdsToRemove = layers.map((l) => l._id);
            const objectsToRemoveFromMap: string[] = [];
            this.msaObjectHash.forEach((msaObjectMap, msaObjectId) => {
                const updatedLayerList = [...new Set(msaObjectMap.layerIds.filter((id) => !layerIdsToRemove.includes(id)))];
                if (updatedLayerList.length) {
                    msaObjectMap.layerIds = updatedLayerList;
                } else {
                    objectsToRemoveFromMap.push(msaObjectId);
                }
            });

            this.handleRemoveMsaObjects(objectsToRemoveFromMap);
        }
    }

    checkCopyrightAttributions(layers: Layer[]) {
        const skylightLayers = layers.filter((l) => l?.SourceType == LayerSourceType.SKYLIGHT);

        if (skylightLayers.length) {
            this.updateCopyrightAttributionState(AttributionType.skylight, 'enabled');
        } else {
            this.updateCopyrightAttributionState(AttributionType.skylight, 'disabled');
        }
    }

    handleRemoveMsaObjects(msaObjectIds: string[]) {
        msaObjectIds.forEach((id) => {
            this.msaObjectHash.delete(id);
        });

        this.msaObjects = Array.from(this.msaObjectHash.values());
        this.correctMsaObjectHistory();
        this.correctSelectedMsaObjects();
        this.msaObjectListSubject.next(this.listMSAObjects());
    }

    handleReceivedMSA(msaObjects: MSAObjectMap[]) {
        msaObjects.forEach((msaObject) => {
            if (msaObject?.Properties?.ShipType === 'AidToNavigation' && msaObject?.Properties?.COG !== '0') {
                msaObject.Properties.COG = '0';
            }

            const layerids = [];
            const msaObjectMap = this.msaObjectHash.get(msaObject._id);
            if (msaObjectMap) {
                layerids.push(...msaObjectMap.layerIds);
            }
            if (msaObject?.layerIds?.length) {
                layerids.push(...msaObject.layerIds);
            }
            if (layerids?.length)
                this.msaObjectHash.set(msaObject._id, {
                    ...{ ...msaObject, Layer_id: null },
                    layerIds: [...new Set(layerids)],
                });
        });

        this.msaObjects = Array.from(this.msaObjectHash.values());
        this.correctMsaObjectHistory();
        this.correctSelectedMsaObjects();
        this.msaObjectListSubject.next(this.listMSAObjects());
    }

    public onMSAObject(msaObjectNotification: MSAObjectMap[]): void {
        const msaObjectsToUpd = msaObjectNotification
            .filter((o) => this.msaObjectHash.get(o._id))
            .map((o) => this.msaObjectNotificationToMsaObject(o));

        this.handleReceivedMSA(msaObjectsToUpd);

        for (const msaObject of msaObjectsToUpd) {
            const predictionActive = this.msaObjectPrediction[msaObject._id];

            if (predictionActive) {
                this.clearMsaObjectPrediction(msaObject);
            }

            if (this.hasMsaObjectHistory(msaObject)) {
                const history = this.msaObjectHistory[msaObject._id];
                const lastHistory = history[history.length - 1];
                if (lastHistory.Properties?.PositionUtc === msaObject.Properties?.PositionUtc) {
                    history.pop();
                } else {
                    //transform previous 'actual position' in history
                    history[history.length - 1] = {
                        Geometry: lastHistory.Geometry,
                        Properties: lastHistory.Properties,
                        MSAObject_id: msaObject._id,
                    } as any;
                }

                this.msaObjectHistory[msaObject._id] = history.concat(msaObject);
            }

            if (msaObject.Properties?.Provider === undefined || msaObject.Properties?.Provider === 'HUMAN') {
                this.msaObjectListSubject.next(this.listMSAObjects());
                this.mapShouldUpdate = false;
            } else {
                this.mapShouldUpdate = true;
            }
        }
    }

    /**
     * Find layers that contains MSAObject.
     * @param {string} id The MSAObject id.
     * @returns A list of layers.
     */
    public findLayersByMSAObjectId(id: string): Layer[] {
        return [...this.mapLayers.filter((layer) => this.msaObjectHash.get(id).layerIds.includes(layer._id))];
    }

    public listMSAObjectByID(id: string) {
        return this.msaObjectHash.get(id);
    }

    private restoreLayers(layerIds: string[]): void {
        const layerFetch = layerIds.map((id) =>
            this.dataService.getLayer(id, false, true).pipe(
                catchError((err) => {
                    console.error('err', err);

                    return of(null);
                }),
            ),
        );
        forkJoin(...layerFetch)
            .pipe(map((layers) => layers.filter((l) => !!l)))
            .subscribe((layers) => this.updateMapLayers$.next({ layers, action: 'add' }));
    }

    private msaObjectNotificationToMsaObject(msaObjectNotification: MSAObjectMap): MSAObjectMap {
        const actualMsaObject = this.msaObjectHash.get(msaObjectNotification._id);
        if (!actualMsaObject) {
            return msaObjectNotification;
        }

        return ExtendedDataUtil.mergeMsaObjectWithNotification(actualMsaObject, msaObjectNotification);
    }

    private setMSAObjectUpdateListeners(): void {
        this.socketService
            .getMSAObjects(() => this.getSituation()._id)
            .subscribe((msaObjectNotification) => {
                const msaObjects = msaObjectNotification.map((o) => this.msaObjectNotificationToMsaObject(o));

                this.handleReceivedMSA(msaObjects.filter((o) => o.HumanActionType == 'Created'));

                this.onMSAObject(
                    msaObjects.filter(
                        (o) => (o.HumanActionType == 'Updated' || o.HumanActionType == 'Undo') && this.msaObjectHash.get(o._id),
                    ),
                );

                this.handleRemoveMsaObjects(msaObjects.filter((o) => o.HumanActionType == 'Deleted').map((o) => o._id));

                let triggerUpdateMap = false;
                for (const msaObject of msaObjects) {
                    if (msaObject.HumanActionType == 'RemoveFromLayer') {
                        if (!this.getMapLayers().some((l) => l._id == msaObject.HumanActionContext.layerId)) {
                            return;
                        }
                        const newObjMap = this.msaObjectHash.get(msaObject._id);
                        if (newObjMap) {
                            newObjMap.layerIds = newObjMap.layerIds.filter((lid) => lid != msaObject.HumanActionContext.layerId);
                            this.msaObjectHash.set(msaObject._id, newObjMap);
                            this.msaObjects = Array.from(this.msaObjectHash.values());
                            triggerUpdateMap = true;
                        }
                    }

                    if (msaObject.HumanActionType == 'AddToLayer') {
                        if (!this.getMapLayers().some((l) => l._id == msaObject.HumanActionContext.layerId)) {
                            return;
                        }
                        const newObjMap = this.msaObjectHash.get(msaObject._id);
                        if (newObjMap) {
                            newObjMap.layerIds = [...newObjMap.layerIds, msaObject.HumanActionContext.layerId];
                            this.msaObjectHash.set(msaObject._id, newObjMap);
                            this.msaObjects = Array.from(this.msaObjectHash.values());
                            triggerUpdateMap = true;
                        }
                    }
                }

                if (triggerUpdateMap) {
                    this.correctMsaObjectHistory();
                    this.correctSelectedMsaObjects();
                    this.msaObjectListSubject.next(this.listMSAObjects());
                }
            });
    }

    private setUserMapConfigListeners(): void {
        combineLatest([this.mapLayers$, this.mapGroupLayers$, this.mapLayerProperties$]).subscribe(
            ([layers, groupLayers, properties]) => {
                const layersConfig: LayerConfig[] = [...layers, ...groupLayers].filter(l => !this.isBackgroundLayer(l)).map((layer) => {
                    const props = properties[layer._id];
                    return {
                        LayerId: layer._id,
                        IsHidden: !!props?.hidden,
                        IsSelected: true,
                        Opacity: props?.opacity || this.opacityDefault,
                    };
                });

                this.userMapConfigService.setLayersConfigFromList(layersConfig);
            },
        );

        this.userMapConfigService.config$
            .pipe(filter((config) => !!config))
            .pipe(first())
            .subscribe((config) => {
                const selectedLayersIds = config.Layers.filter((layerConfig) => layerConfig.IsSelected).map(
                    (layerConfig) => layerConfig.LayerId,
                );

                this.setMapLayerPropertiesFromUserMapConfig(config);
                this.restoreLayers(selectedLayersIds);
            });
    }

    private setNotificationListeners(): void {
        const notifier = this.socketService.getNotifications();

        notifier
            .pipe(filter((n) => n.ObjectType === NotificationObjectType.Chat))
            .pipe(filter((n) => !!this.situation))
            .pipe(flatMap((n) => this.dataService.listChats(ChatFilter.NotHidden, this.situation._id, undefined, undefined)))
            .subscribe((chatList) => (this.chatList = chatList));

        notifier
            .pipe(filter((n) => n.ObjectType === NotificationObjectType.Layer))
            .pipe(
                filter(
                    (n) => n.ActionType === NotificationActionType.Deleted || n.ActionType === NotificationActionType.LostAccess,
                ),
            )
            .pipe(filter((n) => !!this.mapLayers.find((l) => l._id === n.ObjectReference)))
            .subscribe((n) => {
                const layer = this.mapLayers.find((l) => l._id == n.ObjectReference);
                if (layer) return this.updateMapLayers$.next({ layers: [layer], action: 'remove' });
            });

        notifier
            .pipe(filter((n) => n.ObjectType === NotificationObjectType.MSAObject))
            .pipe(filter((n) => n.ActionType === NotificationActionType.Trespass))
            //.pipe(filter(n => !!this.mapLayers.find(l => l._id === n.ObjectReference)))
            .subscribe((n) => {
                const noty = {
                    NotificationType: 'Trespass',
                    Name: n.ObjectInfo.Name,
                    Security: n.ObjectInfo.Security,
                    Sensitivity: n.ObjectInfo.SensitivityLevel,
                    Type: n.ObjectInfo.LayerType,
                    Trespasser: n.Trespasser,
                };
                console.debug('Sending TP notification to notification-control: ', noty);

                this.notificationrControlSubject.next(noty);
            });

        notifier
            .pipe(filter((n) => n.ObjectType === NotificationObjectType.Layer))
            .pipe(filter((n) => n.ActionType === NotificationActionType.Updated))
            .pipe(filter((n) => !!this.mapLayers.find((l) => l._id === n.ObjectReference)))
            .pipe(map((n) => n.ObjectInfo as Layer))
            .subscribe((layer) => {
                const mapLayer = this.mapLayers.find((l) => l._id == layer._id);
                if (layer.LayerType === LayerType.Smart) {
                    if (layer._changes?.MSAObjects_ids) {
                        const noty = {
                            NotificationType: 'Smartlayer',
                            Name: layer.Name,
                            Added: layer._changes.MSAObjects_ids.added.length?.toString() || '0',
                            Removed: layer._changes.MSAObjects_ids.removed.length?.toString() || '0',
                            Security: layer.Security,
                            Sensitivity: layer.SensitivityLevel,
                            Type: layer.LayerType,
                        };

                        this.notificationrControlSubject.next(noty);

                        this.handleRemoveMsaObjects(layer._changes.MSAObjects_ids.removed);
                    }
                    return this.updateMapLayers$.next({ layers: [mapLayer], action: 'add', reload: true });
                } else {
                    return this.updateMapLayers$.next({ layers: [mapLayer], action: 'add', reload: layer?.LayerType === LayerType.File });
                }
            });

        notifier
            .pipe(filter((n) => n.ObjectType === NotificationObjectType.Situation))
            .pipe(filter((n) => n.ActionType === NotificationActionType.Updated))
            .pipe(filter((n) => this.situation?._id === n.ObjectReference))
            .pipe(filter((n) => !n.ObjectInfo.IsActive))
            .subscribe((n) => window.close());

        notifier
            .pipe(filter((n) => n.ObjectType === NotificationObjectType.Situation))
            .pipe(filter((n) => n.ActionType === NotificationActionType.Updated))
            .pipe(filter((n) => this.situation?._id === n.ObjectReference))
            .pipe(filter((n) => n.ObjectInfo.IsActive))
            .pipe(map((n) => n.ObjectInfo))
            .subscribe((s) => this.setSituation(s));

        notifier
            .pipe(filter((n) => n.ObjectType === NotificationObjectType.Situation))
            .pipe(filter((n) => n.ActionType === NotificationActionType.LostAccess))
            .pipe(filter((n) => this.situation?._id === n.ObjectReference))
            .subscribe((n) => window.close());
    }

    private setWindowMessageListener(): void {
        fromEvent(window, 'message')
            .pipe(filter((msg: any) => msg.source === window.opener))
            .pipe(pluck('data'))
            .subscribe((msg) => this.mapMarkerSubject.next(msg));
    }

    private sortSituationLayers(a: Layer, b: Layer): number {
        return this.situation.Layers_ids.indexOf(b._id) - this.situation.Layers_ids.indexOf(a._id);
    }

    private sortMSAObjectHistory(a: MSAObject, b: MSAObject): number {
        const timeA = a.Properties?.PositionUtc || a.UpdatedAt;
        const timeB = b.Properties?.PositionUtc || b.UpdatedAt;
        return timeA > timeB ? 1 : timeA < timeB ? -1 : 0;
    }

    private compareMSAObjects(oldObj: MSAObjectMap, newObj: MSAObjectMap): boolean {
        return oldObj._id === newObj._id && oldObj.UpdatedAt === newObj.UpdatedAt;
    }

    setFlagOptions() {
        const currentLang = this.translateService.currentLang;
        this.dataService.listMsaObjectsDefaultProperties('Flag').subscribe((flags) => {
            this.flagOptions = flags.map((flag) => {
                let translation: string;

                if (currentLang === Language.en) {
                    translation = flag.Translations.English;
                } else if (currentLang === Language.pt) {
                    translation = flag.Translations.Portuguese;
                } else if (currentLang === Language.fr) {
                    translation = flag.Translations.French;
                } else if (currentLang === Language.es) {
                    translation = flag.Translations.Spanish;
                }

                return { label: translation === 'N/A' ? flag.Value : translation, value: flag.Value };
            });
            this.flagOptions = this.flagOptions.sort((a, b) => a.label.localeCompare(b.label));
        });
    }


    private findCloseFirstParentheses(expression: string): number {
        if (!expression.startsWith('(')) {
          return -1;
        }
        let openParenthesesCount = 0;
        for (let i = 0; i < expression.length; i++) {
          if (expression[i] === '(') {
            openParenthesesCount++;
          } else if (expression[i] === ')') {
            openParenthesesCount--;

            if (openParenthesesCount === 0) {
              return i;
            }
          }
        }
        return -1;
      }

      public removeOuterParentheses(expression: string): string {
        expression = expression.trim();
        if (expression.startsWith('(') && expression.endsWith(')')) {
          const index = this.findCloseFirstParentheses(expression);
          if (index === expression.length - 1) {
            return expression.substring(1, expression.length - 1).trim();
          }
        }
        return expression;
      }

    public isBackgroundLayer(layer: Pick<Layer, '_id'>) {
        return layer._id && layer._id === this.permissionsService.getUser().Maps?.Layer_id
    }
}
