import { HttpClient } from '@angular/common/http';
import { AdminBaseService } from './admin-base.service';
import { ITranslationCount, TranslationProviderType } from '../domain';

export class AdminTranslation extends AdminBaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  public async getCount(
    provider: 'all' | TranslationProviderType = 'all',
    query?: string,
    page: number = 0,
    take: number = 100,
  ): Promise<ITranslationCount[]> {
    if (!page) page = 0;
    if (!take) take = 10;
    let q = '';
    q += `page=${page}&take=${take}`;
    if (provider && provider != 'all') {
      q += `&provider=${provider}`;
    }
    if (query) {
      q += `&query=${query}`;
    }
    return this.getAsync(`/light/api/admin/translation/count?${q}`);
  }
}
