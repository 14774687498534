import { Injectable } from '@angular/core';
import { Event, Chat } from '@yaris/core/domain';
import { SocketService } from './socket.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { ErrorModalComponent } from '@yaris/shared/components/error-modal/error-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@yaris/core/modal.service';

@Injectable({
  providedIn: 'root',
})
export class WindowsService {
  openSockets = 0;
  constructor(
    private translateService: TranslateService,
    private modal: ModalService,
    private socketService: SocketService,
  ) {
    this.socketService
      .getCounter()
      .pipe(distinctUntilChanged())
      .subscribe((object) => {
        this.openSockets = object;
      });
  }

  openWindows(situation: string) {
    console.log('Opened: ', this.openSockets);
    //The maximum number of opened windows is 10 corresponding to 1xMSA + 3x(LOG+CHAT+MAP)
    if (this.openSockets < 10) {
      this.openChatWindows(situation);
      this.openLogWindows(situation);
      this.openMSAWindows(situation);
    } else this.errorModal();
  }

  openMSAWindows(situation: string) {
    //The maximum number of opened windows is 10 corresponding to 1xMSA + 3x(LOG+CHAT+MAP)
    if (this.openSockets < 10) {
      const msaWindow = window.open('', 'MSA-' + situation);
      if (!msaWindow || msaWindow.location.host !== window.location.host) {
        window.open('msa/' + situation, 'MSA-' + situation);
      }
    } else this.errorModal();
  }

  /**
   * Open chat room window.
   * @param {string} situation The situation ID.
   * @param {string} room The room ID.
   */
  openChatWindows(situation: string) {
    //The maximum number of opened windows is 10 corresponding to 1xMSA + 3x(LOG+CHAT+MAP)
    if (this.openSockets < 10) {
      const chatWindow = window.open('', 'CHAT-' + situation);
      if (!chatWindow || chatWindow.location.host !== window.location.host) {
        window.open('chat/' + situation, 'CHAT-' + situation);
      }
    } else this.errorModal();
  }

  openChatRoomWindows(situation: string, room: string) {
    //The maximum number of opened windows is 10 corresponding to 1xMSA + 3x(LOG+CHAT+MAP)
    if (this.openSockets < 10) {
      let chatWindow = window.open('', 'CHAT-' + situation + '-ROOM-' + room);
      if (!chatWindow || chatWindow.location.host !== window.location.host) {
        chatWindow = window.open('chat/' + situation + '/room/' + room, 'CHAT-' + situation + '-ROOM-' + room);
      }
    } else this.errorModal();
  }

  openLogWindows(situation: string) {
    //The maximum number of opened windows is 10 corresponding to 1xMSA + 3x(LOG+CHAT+MAP)
    if (this.openSockets < 10) {
      let logWindow = window.open('', 'LOG-' + situation);
      if (!logWindow || logWindow.location.host !== window.location.host) {
        logWindow = window.open('log/' + situation, 'LOG-' + situation);
      }
    } else this.errorModal();
  }

  openEventOnMSAWindow(situation: string, event: Event) {
    let msaWindow = window.open('', 'MSA-' + situation);
    if (!msaWindow || msaWindow.location.host !== window.location.host) {
      msaWindow = window.open('msa/' + situation, 'MSA-' + situation);
      setTimeout((_) => msaWindow.postMessage(event, window.location.origin), 1000);
      return;
    }
    msaWindow.postMessage(event, window.location.origin);
  }

  openChatOnMSAWindow(situation: string, event: Chat) {
    let msaWindow = window.open('', 'MSA-' + situation);
    if (!msaWindow || msaWindow.location.host !== window.location.host) {
      msaWindow = window.open('msa/' + situation, 'MSA-' + situation);
      setTimeout((_) => msaWindow.postMessage(event, window.location.origin), 1000);
      return;
    }
    msaWindow.postMessage(event, window.location.origin);
  }

  openEventOnLOGWindow(situation: string, id: string) {
    let logWindow = window.open('', 'LOG-' + situation);
    if (!logWindow || logWindow.location.host !== window.location.host) {
      logWindow = window.open('log/' + situation, 'LOG-' + situation);
      setTimeout((_) => logWindow.postMessage(id, window.location.origin), 1000);
      return;
    }
    logWindow.postMessage(id, window.location.origin);
  }

  errorModal() {
    this.modal.open({
      title: '',
      contentComponent: ErrorModalComponent,
      closable: true,
      inputs: {
        label: this.translateService.instant('MSA.LAYER.MAXIMUMTABSOPPENED'),
        type: 'Layer',
      },
    });
  }
}
